export const TITLE_SECTION_STYLES = {
  color: 'secondaryColorText',
  fontSize: '24px',
  lineHeight: '24px',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  fontFamily: 'heading',
  letterSpacing: '1.75px'
};

export const HEADING_STYLES = {
  fontWeight: 'normal',
  fontSize: '60px',
  lineHeight: '1.6em',
  textAlign: 'center',
  color: 'secondaryColorText',
  textTransform: 'capitalize',
  fontFamily: 'headingAlternative',
};


export const CONTENT_STYLES = {
  fontWeight: 'normal',
  fontSize: {base: '18px', xs: '20px'},
  color: 'mainColorText',
  fontFamily: 'body',
};
