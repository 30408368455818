

// Mood Board Color
export const BG_PRIMARY = '#F1E4D3'; //creme
export const BG_SECONDARY = '#795D73'; //purple
export const BG_ALTERNATIVE = '#fffbf5'; //peach
export const BG_LAYER = '#f1e4d330'
export const BG_LAYER_BORDER = '#A48761'

// GRADIENT COLORS
export const GRADIENT_COVER = `linear-gradient(to bottom, ${BG_PRIMARY}, ${BG_SECONDARY})`

// Text Color
export const TEXT_PRIMARY = '#555042'; //green dark
export const TEXT_SECONDARY = '#795D73'; // purple dark
export const TEXT_ALTERNATIVE = '#fffbf5'; // Alternative color purple

// Music & Navigation Color
export const NAVIGATION_COLOR = `#b9a68d`; // background navigation
export const ICON_NAVIGATION_COLOR = 'white'; // Icon navigation
// Drawer Color
export const DRAWER_COLOR = 'rgb(116 84 63)';
export const BORDER_COLOR = '#EDE0D1';
export const FOOTER_COLOR = '#7e6c696e';

// Default Button Color
export const BUTTON_PROPS = {
  _active: { opacity: '.8' },
  _hover: { opacity: '.8' },
  _focus: { opacity: '.8' },
};

import { CONTENT_STYLES } from '@/constants/typography'

export const BUTTON_PADDING_PROPS = {
  ...BUTTON_PROPS,
  ...CONTENT_STYLES,
  variant: 'solid',
  padding: '18px 24px',
  borderRadius: '8px',
  background: BG_SECONDARY,
  color: TEXT_ALTERNATIVE,
  size: 'sm',
  boxShadow: 'sm',
  fontSize: '18px',
  position:"relative",
  zIndex:"20"
};

export const DEFAULT_BUTTON_PROPS = {
  fontWeight: 'normal',
};
